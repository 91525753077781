const { parseMarket } = require("../consts/markets")

/**
 *
 * @param {object} parent
 * @returns {string}
 */
const generateParentPath = parent => {
  if (parent && parent.tabs) {
    const slug = parent.tabs.content.slug.current
    const nextParentSlug = generateParentPath(parent.tabs.content.parent)

    if (nextParentSlug) return `${nextParentSlug}/${slug}`
    return slug
  }
  return ""
}

const generatePath = page => {
  if (page) {
    // this runs in node, netlify has probelem with using older node, so won't accept optional chaining
    // potential alternative solution - set node_env on netlify to higher
    let slug = page.tabs && page.tabs.content && page.tabs.content.slug && page.tabs.content.slug.current
    const parsedMarket = parseMarket(page.tabs.content.market)
    const marketSlug = parsedMarket ? `${parsedMarket}/` : ""

    switch (page._type) {
      case "page": {
        const { content } = page.tabs
        const parent = content._rawParent || content.parent
        const parentSlug = generateParentPath(parent)
        const path = parentSlug ? `${marketSlug}${parentSlug}/${slug}/` : `${marketSlug}${slug}/`

        return path
      }
      case "blogPost": {
        const category = page.tabs.content.category.slug.current
        const primaryPath = `${marketSlug}blog`
        const path = `${primaryPath}/${category}/${slug}/`

        return path
      }
      case "cs": {
        const primaryPath = `${marketSlug}case-studies`
        const path = `${primaryPath}/${slug}/`

        return path
      }
      case "industry": {
        const theme = page.tabs.content.theme === "work" ? "work" : "education"
        const primaryPath = `${marketSlug}industries/${theme}`
        const path = `${primaryPath}/${slug}/`

        return path
      }
      case "resource": {
        const category = page.tabs.content.category.slug.current
        const primaryPath = `${marketSlug}resources`
        const path = `${primaryPath}/${category}/${slug}/`

        return path
      }
      case "event": {
        const primaryPath = `${marketSlug}events`
        const category = page.tabs.content.category.slug.current
        const path = `${primaryPath}/${category}/${slug}/`

        return path
      }
      case "industryListing": {
        const path = `${marketSlug}industries/`

        return path
      }
      case "resourceListing": {
        const path = `${marketSlug}resources/`

        return path
      }
      case "csListing": {
        const path = `${marketSlug}case-studies/`

        return path
      }
      case "eventListing": {
        const path = `${marketSlug}events/`

        return path
      }
      case "blogListing": {
        const path = `${marketSlug}blog/`

        return path
      }
      default:
        return ""
    }
  }
}

// this is used in gatsby-node, that's why it's this form of export
module.exports = generatePath
