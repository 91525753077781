import React from "react"
import PropTypes from "prop-types"
import IframeResizer from "iframe-resizer-react"

const Form = ({ link, title }) => {
  return <IframeResizer src={link} width="100%" id="pardot-form" title={title} />
}

Form.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string,
}

Form.defaultProps = {
  link: "",
  title: "",
}

export default Form
