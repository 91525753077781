import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import CTALeftImage from "../../../assets/svgs/CTA_left.svg"
import CTARightImage from "../../../assets/svgs/CTA_right.svg"

import * as styles from "./cta.module.scss"
import Buttons from "../buttons/buttons"

function CTA(props) {
  const { title, text, buttons, className } = props
  const hasButtons = !!buttons?.length

  return (
    <div className={classNames(styles.cta, className)}>
      <div className={styles.inner}>
        <h2 className={classNames("mb-xs text-align-center", hasButtons && "mt-s")}>{title}</h2>
        <div className={styles.body}>{text}</div>
        <Buttons className={styles.buttonContainer} buttons={buttons} xl />
      </div>
      <div className={styles.backgroundImageContainer} style={{ opacity: 0.5 }}>
        <CTALeftImage className={styles.leftBackground} />
        <CTARightImage className={styles.rightBackground} />
      </div>
    </div>
  )
}

CTA.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.any),
  className: PropTypes.string,
}

CTA.defaultProps = {
  title: "",
  text: "",
  buttons: [],
  className: "",
}

export default CTA
